<template>
  <v-slider
    :disabled="disabled"
    class="slider"
    tick-size="18"
    track-color="elements"
    track-fill-color="elements"
    :min="min"
    :max="max"
    :step="step"
    :value="value"
    @end="onEnd"
    @input="updateValue"
    @start="onStart"
  />
</template>

<script setup lang="ts">

withDefaults(
  defineProps<{
    disabled?: boolean,
    min: number,
    max?: number,
    step?: number,
    value?: number,
  }>(),
  {
    min: 0,
    max: 100,
    step: 1,
    value: 0,
  }
)

const emit = defineEmits<{
  (e: "end", value?: number): void;
  (e: "input", value?: number): void;
  (e: "start", value?: number): void;
}>();

function updateValue(value: number) {
  emit("input", value);
}

function onStart(value: number) {
  emit("start", value);
}

function onEnd(value: number) {
  emit("end", value);
}
</script>

<style lang="scss">
@import "../assets/styles/main";

.slider {
  .v-slider__thumb {
    box-shadow: inset 0 0 0 4px $primaryWhite  !important;
    width: 20px;
    height: 20px;
    left: -10px;

    &::before,
    &::after {
      content: none !important;
    }

    &:hover {
      box-shadow: inset 0 0 0 4px $primaryWhite, 2px 2px 2px rgba(33, 42, 52, 0.32) !important;
    }

    &:active {
      box-shadow: inset 0 0 0 4px $primaryWhite  !important;
      background-color: $secondary  !important;
    }
  }

  .v-slider__track-container {
    height: 8px !important;

    .v-slider__track-background {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .v-slider__track-fill {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      background: rgba(33, 42, 52, 0.72) !important;
      backdrop-filter: blur(4px) !important;
    }
  }
}
</style>
